import React from "react"
import Button from "./mainButton"

const SubServices = props => {
  return (
    <section id="services">
      <div className="fullWidth bg-blue-400 py-16 px-2 md:px-0">
        <div className="container">
          <div className="md:w-full lg:mr-8">
            <div className="md:flex md:mb-8">
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleOne}</h3>
                  <p className="text-gray-600 mb-4 text-base">
                    {props.descriptionOne}
                  </p>
                  <Button link={props.linkOne} />
                </div>
              </div>
              <div className="md:w-1/2 lg:max-w-1/4 rounded bg-white overflow-hidden shadow-lg mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleTwo}</h3>
                  <p className="text-gray-600 mb-4 text-base">
                    {props.descriptionTwo}
                  </p>
                  <Button link={props.linkTwo} />
                </div>
              </div>
            </div>
            <div className="md:flex justify-center">
              <div className="md:w-1/2 lg:max-w-1/4 bg-white rounded overflow-hidden shadow-lg md:mr-8 mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleThree}</h3>
                  <p className="text-gray-600 mb-4 text-base">
                    {props.descriptionThree}
                  </p>
                  <Button link={props.linkThree} />
                </div>
              </div>
              <div className="md:w-1/2 rounded bg-white overflow-hidden shadow-lg mb-8 md:mb-0">
                <div className="px-3 py-4 md:px-6 md:py-8">
                  <h3 className="font-bold text-xl mb-4">{props.titleFour}</h3>
                  <p className="text-gray-600 mb-4 text-base">
                    {props.descriptionFour}
                  </p>
                  <Button link={props.linkFour} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubServices
