import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import SubServicesEven from "../../components/subServicesEven"
import HeroSection from "../../components/heroSection"

function Drains() {
  const data = useStaticQuery(graphql`
    query drainsImages {
      drain: file(relativePath: { eq: "drain-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Drain Unclogging & Repair"
        description={`Do you have a stopped-up drain? Our experts can unclog and repair your drain so you can start using your sink, bathtub, shower, or washing machine.`}
      />
      <HeroSection
        h1={`Drain Repair Services`}
        h2={`Have an Experienced Master Plumber Fix Your Clogged Drain`}
        h3={`Klein Plumbing Services has over 30 years of experience working on clogged bathroom, sink, and washing machine drains.`}
        p1={`We've repaired countless drains over three decades of serving the North Houston area, which is why our service is fast, affordable, and thorough.`}
        heroImg={data.drain.childImageSharp.fluid}
        heroImgAltTag={`Drain Repair Services`}
      />
      <SubServicesEven
        titleOne={`Sink Drains`}
        descriptionOne={`Is your kitchen or bathroom sink not draining? No matter what the cause, our expert plumber can unclog it quickly so you can use your sink again.`}
        titleTwo={`Shower Drains`}
        descriptionTwo={`Debris, hair, and other material can cause your shower drain to back up. Slow-draining showers are a nuisance, but our plumbers can help.`}
        titleThree={`Bathtub Drains`}
        descriptionThree={`Are you dealing with standing water every time after you use your bathtub? Our experts are able to unclog your drain like new.`}
        titleFour={`Washing Machine Drains`}
        descriptionFour={`If your washing machine isn't draining properly, water may back up that won't allow your clothes to get clean. We can get your drain to work more efficiently.`}
        linkOne={`/plumbing/drains/unclog-sink-drain`}
        linkTwo={`/plumbing/drains/unclog-shower-drain`}
        linkThree={`/plumbing/drains/unclog-bathtub-drain`}
        linkFour={`/plumbing/drains/washing-machine-drain`}
      />
      <ServiceAreas
        description={`drains`}
        subDescription={`drain clogs and repair`}
      />
      <CTA />
    </Layout>
  )
}

export default Drains
