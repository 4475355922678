import React from "react"
import { Link } from "gatsby"

function Button(props) {
  return (
    <div>
      <Link
        to={props.link}
        className="inline-block md:text-base lg:text-lg px-4 py-2 border border-blue-400 leading-none bg-blue-600 rounded text-white hover:bg-blue-700 lg:mt-0"
      >
        {props.title || `Learn More`}
      </Link>
    </div>
  )
}

export default Button
